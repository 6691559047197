import '../styles/globals.css'
import { RecoilRoot } from "recoil";
import ProgressBar from "@badrap/bar-of-progress";
import { Router } from "next/router";
import React from "react";
import ErrorBoundary from '../components/ErrorBoundary';

const progress = new ProgressBar({
    size: 2,
    color: "#28166F",
    className: 'bar-of-progress',
    delay: 0
})

Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeComplete", progress.finish);
Router.events.on("routeChangeError", progress.finish)

function MyApp({ Component, pageProps }) {
    return (
        <RecoilRoot>
            <Component {...pageProps} />
        </RecoilRoot>
    )
}

export default MyApp
